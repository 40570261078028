import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
    },

    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
        ] = `MOD ${JwtService.getToken()}`;
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },

    get(resource) {
        return Vue.axios.get(`${resource}`).catch(error => {
            // if (error.response) {
            //     // The request was made and the server responded with a status code
            //     // that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     console.log(error.response.headers);
            // } else if (error.request) {
            //     // The request was made but no response was received
            //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //     // http.ClientRequest in node.js
            //     console.log(error.request);
            // } else {
            //     // Something happened in setting up the request that triggered an Error
            //     console.log('Error', error.message);
            // }
            // console.log(error.config);
            let data = error.response.data;
            throw new Error(data.localizationMessage ? data.localizationMessage : data.message);
        });
    },

    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;

export const OpenApiService = {

    get(key) {
        return ApiService.get(`core/api/open/appointment/details/${key}`);
    },
    getOrg(key) {
        return ApiService.get(`core/api/open/organization/${key}`);
    },
    getActiveStaff(key) {
        return ApiService.get(`core/api/open/organization/${key}/staff`);
    },
    getStaff(params) {
        return ApiService.get(`core/api/open/organization/${params.key}/staff/${params.staffId}`);
    },
    getComments(params) {
        return ApiService.get(`core/api/open/organization/${params.key}/staff/${params.staffId}/feedback?from=${params.from}&to=${params.to}`);
    },
    getCommentPages(params) {
        return ApiService.get(`core/api/open/organization/${params.key}/staff/${params.staffId}/feedback/pages?offset=${params.offset}`);
    },
    getServices(key) {
        return ApiService.get(`core/api/open/organization/${key}/services`);
    },
    post(body) {
        return ApiService.post("core/api/open/feedback", body);
    }
};
