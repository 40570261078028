<template lang="pug">
  nav#mainNav.navbar.navbar-expand-lg.navbar-light.fixed-top.shadow-sm
    .container.px-5
      img.app-badge(src="issist/logo.svg" href="/")
      button.navbar-toggler(type='button', data-bs-toggle='collapse', data-bs-target='#navbarResponsive', aria-controls='navbarResponsive', aria-expanded='false', aria-label='Toggle navigation')
        i.bi-list.me-2
      #navbarResponsive.collapse.navbar-collapse
        //ul.navbar-nav.ms-auto.me-4.my-3.my-lg-0
        //  li.nav-item
        //    a.nav-link.me-lg-3(v-on:click="scrollTo('features')") {{ $t('issist.nav.features')}}
        //  li.nav-item
        //    a.nav-link.me-lg-3(v-on:click="scrollTo('download')") {{ $t('issist.nav.app')}}
        //button.btn.btn-primary.rounded-pill.px-3.mb-2.mb-lg-0(data-bs-toggle='modal', data-bs-target='#feedbackModal')
        //  span.d-flex.align-items-center
        //    i.bi-chat-text-fill.me-2
        //    span.small Feedback

</template>
<script>
import BaseNav from "@/components/BaseNav";
// import BaseDropdown from "@/components/BaseDropdown";
// import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    // CloseButton,
    // BaseDropdown
  }
};
</script>
<style>
</style>
