var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:[
     {'card-lift--hover': _vm.hover},
     {'shadow': _vm.shadow},
     {[`shadow-${_vm.shadowSize}`]: _vm.shadowSize},
     {[`bg-gradient-${_vm.gradient}`]: _vm.gradient},
     {[`bg-${_vm.type}`]: _vm.type}
   ]},[(_vm.$slots.header)?_c('div',{staticClass:"card-header",class:_vm.headerClasses},[_vm._t("header")],2):_vm._e(),(!_vm.noBody)?_c('div',{staticClass:"card-body",class:_vm.bodyClasses},[_vm._t("default")],2):_vm._e(),(_vm.noBody)?_vm._t("default"):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"card-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }